import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader from "../../components/common/PageHeader";
import { useXhr } from "hooks/useXhr";
import { Button, Spinner } from "react-bootstrap";
import html2canvas from "html2canvas";

//images
import Logo from "../../assets/images/logos/Logo.png"
import Facebook from "../../assets/images/logos/Facebook.png"
import LinkedIn from "../../assets/images/logos/LinkedIn.png"
import Instagram from "../../assets/images/logos/Instagram.png"
import Youtube from "../../assets/images/logos/Youtube.png"

function Signatures() {
    //States
    const [item, setItem] = useState({
        name1: null,
        title: null,
        titleEng: null,
        mobile: null,
        mobile2: null,
        phone: null,
        fax: null,
        email: null,
        bannerID: null
    });
    const [signatures, setSignatures] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [banners, setBanners] = useState(null);
    const [links, setLinks] = useState(null);
    const [kvkk, setKvkk] = useState(null);
    const [signatureWidth, setSignatureWidth] = useState(null);
    const [signatureStatus, setSignatureStatus] = useState("all");
    const [image, setImage] = useState({
        signature: null,
        facebook: null,
        linkedin: null,
        instagram: null,
        youtube: null
    });
    const [base64, setBase64] = useState({
        banner: null
    });
    const [isCheck, setIsCheck] = useState(null);
    const [status, setStatus] = useState(false);
    const [isUpdate, setIsUpdate] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedHtml, setIsCopiedHtml] = useState(false);
    const [error, setError] = useState({
        field: null,
        note: null
    });

    //Const
    const requiredFields = ['name1', 'title', 'phone', 'email'];
    const maxSignature = 200;

    //Hooks
    const { customXhr } = useXhr()

    //Handle Functions
    const handleSelectChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSelectUpdate = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnChange = (e) => {
        const { id, value } = e.target;

        setItem(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleOnUpdateChange = (e) => {
        const { id, value } = e.target;

        setIsUpdate(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'delete');
        formData.append('id', id);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setStatus(!status);
            setIsDelete(null);
            setIsLoading(false);
        };
    };

    const handleUpdate = async () => {
        for (const field of requiredFields) {
            if (!isUpdate[field] || isUpdate[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'update');
        formData.append('id', isUpdate.id);
        formData.append('name1', isUpdate.name1);
        formData.append('title', isUpdate.title);
        formData.append('titleEng', isUpdate.titleEng);
        formData.append('mobile', isUpdate.mobile);
        formData.append('mobile2', isUpdate.mobile2);
        formData.append('phone', isUpdate.phone);
        formData.append('fax', isUpdate.fax);
        formData.append('email', isUpdate.email);
        formData.append('bannerID', isUpdate.bannerID);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setIsUpdate(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    const handleSubmit = async () => {
        for (const field of requiredFields) {
            if (!item[field] || item[field] === "") {
                return (
                    setError({
                        field: field,
                        note: `Zorunlu alan`
                    }),
                    setIsLoading(false)
                )
            }
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'insert');
        formData.append('name1', item.name1);
        formData.append('title', item.title);
        formData.append('titleEng', item.titleEng);
        formData.append('mobile', item.mobile);
        formData.append('mobile2', item.mobile2);
        formData.append('phone', item.phone);
        formData.append('fax', item.fax);
        formData.append('email', item.email);
        formData.append('bannerID', item.bannerID);

        const response = await customXhr(formData);

        if (response.status === "success") {
            setItem({
                name1: null,
                title: null,
                titleEng: null,
                mobile: null,
                mobile2: null,
                phone: null,
                fax: null,
                email: null,
                bannerID: null
            });
            setIsModal(null);
            setStatus(!status);
            setError({
                field: null,
                note: null
            });
            setIsLoading(false);
        };
    };

    //Get Functions
    const getSignatures = async () => {
        const formData = new FormData();
        formData.append('module', 'signatures');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setSignatures(response);
            setFilteredData(response);
            getBanners();
        };
    };

    const getBanners = async () => {
        const formData = new FormData();
        formData.append('module', 'banners');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setBanners(response);
            getLinks();
        };
    };

    const getLinks = async () => {
        const formData = new FormData();
        formData.append('module', 'links');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setLinks(response);
            getKvkk();
        };
    };

    const getKvkk = async () => {
        const formData = new FormData();
        formData.append('module', 'kvkk');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setKvkk(response[0]);
            getSignatureWidth();
        };
    };

    const getSignatureWidth = async () => {
        const formData = new FormData();
        formData.append('module', 'signature_width');
        formData.append('action', 'list');

        const response = await customXhr(formData);

        if (response) {
            setSignatureWidth(Number(response[0].signatureWidth));
        };
    };

    //Other Functions
    const handleCopy = (page, id) => {
        const textToCopy = `${window.location.origin}/open/${page}/${id}`;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                if (page === "signature") {
                    setIsCopied(true);
                    setIsCopiedHtml(false);
                } else if (page === "html-page") {
                    setIsCopiedHtml(true);
                    setIsCopied(false);
                };
            })
            .catch((err) => {
                alert("Kopyalama işlemi başarısız oldu.", err);
                if (page === "signature") {
                    setIsCopied(false);
                } else if (page === "html-page") {
                    setIsCopiedHtml(false);
                };
            });
    };

    const formatPhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
    };

    const convertBannerToBase64 = (url) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setBase64(prevState => ({
                        ...prevState,
                        banner: reader.result
                    }));
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                console.error('Görüntü yüklenirken bir hata oluştu:', error);
            });
    };

    const generateImageFromHTML = (htmlCode, field) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.left = '-9999px';
        tempDiv.style.fontFamily = "Roboto, sans-serif";
        tempDiv.innerHTML = htmlCode;

        document.body.appendChild(tempDiv);

        html2canvas(tempDiv, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            setImage(prevState => ({
                ...prevState,
                [field]: imgData
            }));
            setSignatureStatus(field);
            document.body.removeChild(tempDiv);
        });
    };

    const searchFunction = (query) => {
        if (query.length > 2 && signatures) {
            const lowerQuery = query.toLowerCase();
            const filtered = signatures.filter(obj => {
                return Object.values(obj).some(value => {
                    if (typeof value === "string") {
                        return value.toLowerCase().includes(lowerQuery);
                    }
                    return false;
                });
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(signatures);
        };
    };

    const handleSearchChange = (event) => {
        searchFunction(event.target.value);
    };

    //Effects
    useEffect(() => {
        getSignatures();
    }, [status]);

    useEffect(() => {
        if (isCheck && links && signatureWidth && Logo && Facebook && LinkedIn && Instagram && Youtube) {
            if (signatureStatus === "all") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; width: ${signatureWidth}px; padding: 20px 0;">
                            <div style="flex: 1; width: ${Math.floor(signatureWidth / 2) - 1}px; padding: 0 0 0 15px; border-right: 2px solid #676767; font-size: 14px; text-align: left;">
                                <h4 style="margin: 0 0 5px 0; width: ${Math.floor(signatureWidth / 2) - 1}px; font-size: 20px;">${isCheck.name1}</h4>
                                <p style="font-size: 16px; margin: 0;">${isCheck.title}</p>
                                ${isCheck.titleEng !== null && isCheck.titleEng !== "null" && isCheck.titleEng !== "" ?
                                    (`<p style="font-size: 16px;">${isCheck.titleEng}</p>`) : ""}
                                <p>
                                    Osmangazi Mah. Mehmet Deniz Kopuz Cad.
                                    <br />
                                    No:21-23/1 Kıraç - Esenyurt / İstanbul
                                </p>
                                ${isCheck.mobile !== null && isCheck.mobile !== "null" && isCheck.mobile !== "" ?
                                    (`
                                        <p style = "margin: 0;" >
                                            G: +90 ${formatPhoneNumber(isCheck.mobile)}
                                        </p> 
                                    `) : ""
                                }
                                ${isCheck.mobile2 !== null && isCheck.mobile2 !== "null" && isCheck.mobile2 !== "" ?
                                    (`
                                        <p style = "margin: 0;" >
                                            G: +90 ${formatPhoneNumber(isCheck.mobile2)}
                                        </p> 
                                    `) : ""
                                }
                                <p style = "margin: 0;" >
                                    T: +90 ${formatPhoneNumber(isCheck.phone)}
                                </p>
                                <p>
                                    F: +90 ${formatPhoneNumber(isCheck.fax)}
                                </p>
                                <p style="padding-top: 20px">
                                    ${isCheck.email}
                                </p>
                            </div>
                            <div style="flex: 1; width: ${Math.floor(signatureWidth / 2) - 1}px; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: center; font-size: 12px; line-height: 1.5;">
                                <img src=${Logo} style="width: ${Math.floor(((signatureWidth / 2) - 1) / 100 * 80)}px; display: block; margin: 0 auto; object-fit: cover;" alt="Logo" />
                                <p style="margin-top: 20px; font-size: 14px;">camglass.com.tr</p>
                            </div>
                        </div>
                    `,
                    "signature"
                );
            };
            if (signatureStatus === "signature") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center; padding: 15px 0;">
                            <img src=${Facebook} alt="Facebook" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[0].name}</p>
                        </div>
                    `,
                    "facebook"
                );
            };
            if (signatureStatus === "facebook") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center; padding: 15px 0;">
                            <img src=${LinkedIn} alt="LinkedIn" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[1].name}</p>
                        </div>
                    `,
                    "linkedin"
                );
            };
            if (signatureStatus === "linkedin") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center; padding: 15px 0;">
                            <img src=${Instagram} alt="Instagram" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[2].name}</p>
                        </div>
                    `,
                    "instagram"
                );
            };
            if (signatureStatus === "instagram") {
                generateImageFromHTML(
                    `
                        <div style="display: flex; justify-content: center; padding: 15px 0;">
                            <img src=${Youtube} alt="YouTube" style="width: 25px; margin-right: 2px;" />
                            <p style="font-size: 12px; margin: auto; padding-right: 10px;">${links[3].name}</p>
                        </div>
                    `,
                    "youtube"
                );
            };
            if (signatureStatus === "youtube") {
                setSignatureStatus(null);
            }
        };
    }, [isCheck, links, signatureWidth, signatureStatus, image, base64, Facebook, LinkedIn, Instagram, Youtube]);

    useEffect(() => {
        if (isCheck && banners && signatureWidth) {
            if (isCheck.bannerID) {
                const banner = banners.find((banner) => banner.id === isCheck.bannerID);
                if (banner) {
                    const imageUrl = `${process.env.PUBLIC_URL}/uploads/banners/${banner.photoName}`;
                    convertBannerToBase64(imageUrl);
                }
            }
        }
    }, [isCheck, banners, signatureWidth]);

    //Columns
    var columnT = ""

    columnT = [
        {
            name: "AD SOYAD",
            selector: (row) => row.name1,
            sortable: true,
            cell: (row) => <a href="#" className="fw-bold text-primary">{row.name1}</a>
        },
        {
            name: "POZİSYON",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "BANNER",
            selector: (row) => row.bannerID,
            sortable: true,
            cell: (row) => <div>{banners.map(obj => obj.id === row.bannerID ? obj.name1 : null).filter(label => label !== null)[0]}</div>
        },
        {
            name: "E-POSTA",
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: "CEP TELEFONU",
            selector: (row) => row.mobile,
            sortable: true
        },
        {
            name: "AKSİYON",
            selector: (row) => { },
            sortable: true,
            cell: (row) => <div className="btn-group" role="group" aria-label="Basic outlined example">
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Türkçe Güncelleme"
                    onClick={() => setIsUpdate(row)}
                >
                    <i className="icofont-edit text-success"></i>
                </button>
                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İmzayı İncele"
                    onClick={() => setIsCheck(row)}
                >
                    <i className="icofont-eye-alt text-primary"></i>
                </button>
                <a
                    type="button"
                    className="btn btn-outline-secondary"
                    title="İmza Ekranı"
                    href={`/open/signature/${row.id}`}
                    target="_blank"
                >
                    <i className="icofont-pen-alt-4 text-dark"></i>
                </a>
                <a
                    type="button"
                    className="btn btn-outline-secondary"
                    title="Html Ekranı"
                    href={`/open/html-page/${row.id}`}
                    target="_blank"
                >
                    <i className="icofont-code-alt text-warning" style={{ fontWeight: "900" }}></i>
                </a>
                <button
                    type="button"
                    className="btn btn-outline-secondary deleterow"
                    title="Sil"
                    onClick={() => setIsDelete(row)}
                >
                    <i className="icofont-ui-delete text-danger"></i>
                </button>
            </div>
        }
    ]

    if (filteredData && banners) {
        return (
            <div className="container-xxl">
                <PageHeader headerTitle="İmzalar" renderRight={() => {
                    return (
                        <>
                            {signatures.length <= maxSignature &&
                                <div className="col-auto d-flex w-sm-100">
                                    <button className="btn btn-dark btn-set-task w-sm-100" onClick={() => { setIsModal(true) }}>
                                        <i className="icofont-plus-circle me-2 fs-6"></i>
                                        Yeni İmza
                                    </button>
                                </div>
                            }
                            <div className="input-group flex-nowrap input-group-lg mt-2">
                                <div className="input-group-text"><i className="fa fa-search"></i></div>
                                <input
                                    type="search"
                                    className="form-control"
                                    onChange={handleSearchChange}
                                    placeholder="İmza Ara"
                                    aria-label="search"
                                    aria-describedby="addon-wrapping"
                                />
                            </div>
                        </>
                    )
                }} />
                <div className="row clearfix g-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataTable
                                    title="İmzalar"
                                    columns={columnT}
                                    data={[...filteredData].reverse()}
                                    defaultSortField="title"
                                    pagination
                                    selectableRows={false}
                                    className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                                    highlightOnHover={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={isModal}
                    onHide={() => {
                        setIsModal(null);
                        setItem({
                            name1: null,
                            title: null,
                            titleEng: null,
                            mobile: null,
                            mobile2: null,
                            phone: null,
                            fax: null,
                            email: null,
                            bannerID: null
                        });
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Yeni İmza</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {banners &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="name1" className="form-label">Ad Soyad</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name1"
                                        value={item && item.name1}
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"} ` }}
                                    />
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Pozisyon</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        value={item && item.title}
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "title" ? "red" : "var(--border-color)"} ` }}
                                    />
                                    {error.field === "title" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="titleEng" className="form-label">{"İngilizce Pozisyon (Opsiyonel)"}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="titleEng"
                                        value={item && item.titleEng}
                                        onChange={handleOnChange}
                                        style={{ borderColor: `${error.field === "titleEng" ? "red" : "var(--border-color)"} ` }}
                                    />
                                    {error.field === "titleEng" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="bannerID" className="form-label">Banner Seçiniz</label>
                                        <select
                                            id="bannerID"
                                            className="form-select"
                                            onChange={handleSelectChange}
                                        >
                                            <option value="">Seçiniz...</option>
                                            <option value="">Banner Yok</option>
                                            {banners.map((option) => (
                                                <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="email" className="form-label">E-Posta</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={item && item.email}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "email" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="phone" className="form-label">Sabit Telefon</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            value={item && item.phone}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "phone" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="fax" className="form-label">Faks</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="fax"
                                            value={item && item.fax}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "fax" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="mobile" className="form-label">{"Cep Telefonu (Opsiyonel)"}</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile"
                                            value={item && item.mobile}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "mobile" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="mobile2" className="form-label">{"Cep Telefonu 2 (Opsiyonel)"}</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile2"
                                            value={item && item.mobile2}
                                            onChange={handleOnChange}
                                        />
                                        {error.field === "mobile2" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Lütfen telefon numaralarını yazarken başına sıfır(0) koymayınız."}
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={isUpdate}
                    onHide={() => {
                        setIsUpdate(null);
                        setError({
                            field: null,
                            note: null
                        });
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">İmzayı Düzenle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isUpdate && banners &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="name1" className="form-label">Ad Soyad</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name1"
                                        value={isUpdate && isUpdate.name1}
                                        onChange={handleOnUpdateChange}
                                        style={{ borderColor: `${error.field === "name1" ? "red" : "var(--border-color)"} ` }}
                                    />
                                    {error.field === "name1" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Pozisyon</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        value={isUpdate && isUpdate.title}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "title" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="titleEng" className="form-label">{"İngilizce Pozisyon (Opsiyonel)"}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="titleEng"
                                        value={isUpdate && isUpdate.titleEng}
                                        onChange={handleOnUpdateChange}
                                    />
                                    {error.field === "titleEng" &&
                                        <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                    }
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="bannerID" className="form-label">Banner Seçiniz</label>
                                        <select
                                            id="bannerID"
                                            className="form-select"
                                            onChange={handleSelectUpdate}
                                        >
                                            <option value="">Banner Yok</option>
                                            {banners.map((option) => (
                                                option.id === isUpdate.bannerID ?
                                                    <option selected key={option.id} value={option.id}>{option.name1}</option> :
                                                    <option key={option.id} value={option.id}>{option.name1}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="email" className="form-label">E-Posta</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={isUpdate && isUpdate.email}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "email" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="phone" className="form-label">Sabit Telefon</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            value={isUpdate && isUpdate.phone}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "phone" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="fax" className="form-label">Faks</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="fax"
                                            value={isUpdate && isUpdate.fax}
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "fax" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="row g-3 mb-3">
                                    <div className="col-sm">
                                        <label htmlFor="mobile" className="form-label">{"Cep Telefonu (Opsiyonel)"}</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile"
                                            value={ isUpdate ? (isUpdate.mobile !== null && isUpdate.mobile !== "null" && isUpdate.mobile !== "" ? isUpdate.mobile : "") : "" }
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "mobile" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                    <div className="col-sm">
                                        <label htmlFor="mobile2" className="form-label">{"Cep Telefonu 2 (Opsiyonel)"}</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="mobile2"
                                            value={ isUpdate ? (isUpdate.mobile2 !== null && isUpdate.mobile2 !== "null" && isUpdate.mobile2 !== "" ? isUpdate.mobile2 : "") : "" }
                                            onChange={handleOnUpdateChange}
                                        />
                                        {error.field === "mobile2" &&
                                            <label className="form-label" style={{ color: "red" }}>{error.note}</label>
                                        }
                                    </div>
                                </div>
                                <div className="form-label" style={{ color: "red" }}>
                                    {"Lütfen telefon numaralarını yazarken başına sıfır(0) koymayınız."}
                                </div>
                            </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => handleUpdate()}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Yükleniyor...
                                    </> :
                                    <>
                                        Kaydet
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={isCheck}
                    onHide={() => {
                        setIsCheck(null);
                        setIsCopied(false);
                        setSignatureStatus("all");
                        setImage({
                            signature: null,
                            facebook: null,
                            linkedin: null,
                            instagram: null,
                            youtube: null
                        });
                        setBase64({
                            logo: null,
                            banner: null
                        });
                    }}
                    dialogClassName="modal-xl"
                >
                    {isCheck && links && banners && kvkk && signatureStatus === null &&
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title className="fw-bold">{`${isCheck.name1} E-Posta İmzası`}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: "white", padding: "30px 0" }}>
                                    <a href="https://www.camglass.com.tr">
                                        <img src={image.signature} alt="Signature" />
                                    </a>

                                    {/* Social Media Links */}
                                    {links && (
                                        <div style={{ display: 'flex', justifyContent: 'center', width: (Math.floor(signatureWidth / 2) - 1) }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-around', width: "100%", marginLeft: "15px" }}>
                                                {links.map((link, index) => (
                                                    <a key={index} href={link.link} style={{ textAlign: 'center', height: "55px" }}>
                                                        <img
                                                            src={
                                                                index === 0 ? image.facebook :
                                                                    index === 1 ? image.linkedin :
                                                                        index === 2 ? image.instagram :
                                                                            image.youtube
                                                            }
                                                            alt={
                                                                index === 0 ? "Facebook" :
                                                                    index === 1 ? "LinkedIn" :
                                                                        index === 2 ? "Instagram" :
                                                                            "YouTube"
                                                            }
                                                        />
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {/* Banner */}
                                    {isCheck.bannerID !== null && isCheck.bannerID !== "null" && isCheck.bannerID !== "" && (
                                        <div style={{ display: 'flex', justifyContent: 'center', width: (signatureWidth), padding: "30px 15px" }}>
                                            <img src={base64.banner} width={signatureWidth} style={{ display: "block" }} alt="Banner" />
                                        </div>
                                    )}

                                    {/* KVKK */}
                                    <div style={{ padding: "0 15px" }} dangerouslySetInnerHTML={{ __html: kvkk.text1 }} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    className="me-1 ms-4"
                                    onClick={() => handleCopy("signature", isCheck.id)}
                                    disabled={isCopied}
                                >
                                    {
                                        isCopied ?
                                            <>
                                                <i className="icofont-checked text-white me-1"></i>
                                                Kopyalandı
                                            </> :
                                            <>
                                                İmza Linkini Kopyala
                                            </>
                                    }
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="me-1 ms-4"
                                    onClick={() => handleCopy("html-page", isCheck.id)}
                                    disabled={isCopiedHtml}
                                >
                                    {
                                        isCopiedHtml ?
                                            <>
                                                <i className="icofont-checked text-white me-1"></i>
                                                Kopyalandı
                                            </> :
                                            <>
                                                Html Linkini Kopyala
                                            </>
                                    }
                                </Button>
                            </Modal.Footer>
                        </>
                    }
                </Modal>
                <Modal
                    centered
                    show={isDelete}
                    onHide={() => {
                        setIsDelete(null);
                        setIsLoading(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">Uyarı</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className="form-label">
                                Bu imzayı silmek istediğinize emin misiniz?
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            className="me-1 text-white"
                            onClick={() => handleDelete(isDelete.id)}
                            disabled={isLoading}
                        >
                            {
                                isLoading ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" />
                                        Siliniyor...
                                    </> :
                                    <>
                                        <i className="icofont-ui-delete"></i>
                                        &ensp;
                                        Sil
                                    </>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default Signatures;